import React from "react";
import { Link } from 'react-router-dom';

const Impact = () => {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container grid gap-8 px-4 md:px-6">
        <div className="space-y-3 text-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Our Microfinance Impact
          </h2>
          <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
            See how our microfinance initiatives are transforming lives and
            communities around the world.
          </p>
        </div>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 1"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Empowering Women Entrepreneurs
              </h3>
              <p className="text-sm text-muted-foreground">
                Providing access to capital and business training for
                underserved women.
              </p>
            </div>
          </Link>
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 2"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Financing Sustainable Agriculture
              </h3>
              <p className="text-sm text-muted-foreground">
                Helping smallholder farmers invest in climate-smart
                technologies.
              </p>
            </div>
          </Link>
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 3"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Expanding Access to Education
              </h3>
              <p className="text-sm text-muted-foreground">
                Providing loans for students to pursue higher education.
              </p>
            </div>
          </Link>
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 4"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Supporting Small Business Growth
              </h3>
              <p className="text-sm text-muted-foreground">
                Offering loans and mentorship to local entrepreneurs.
              </p>
            </div>
          </Link>
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 5"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Improving Financial Inclusion
              </h3>
              <p className="text-sm text-muted-foreground">
                Bringing banking services to underserved communities.
              </p>
            </div>
          </Link>
          <Link
            href="#"
            className="group relative overflow-hidden rounded-lg"
            prefetch={false}
          >
            <img
              src="/placeholder.svg"
              width={400}
              height={300}
              alt="Project 6"
              className="aspect-[4/3] w-full object-cover transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-background/90 to-transparent p-4 transition-all duration-300 group-hover:bg-gradient-to-b">
              <h3 className="text-lg font-semibold text-foreground">
                Empowering Rural Communities
              </h3>
              <p className="text-sm text-muted-foreground">
                Providing access to credit and financial services in remote
                areas.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Impact;
